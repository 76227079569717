<template>
  <div
    ref="container"
    class="container"
    style="width: 1400px; height: 800px"
  ></div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, defineProps, reactive } from 'vue';
import G6 from '@antv/g6';
import { getEquipmentDetail, getCollectorList } from '@/api/equipment';
import { ElMessage, ElMessageBox } from 'element-plus';

const FONTSIZE = 12; // 字体大小

const ICONFIRSTWIDTH = 40; // 一级图标的宽
const ICONFIRSTHEIGHT = 40; // 一级图标的宽
const ICONSECONDWIDTH = 28; // 二级图标的宽
const ICONSECONDHEIGHT = 28; // 二级图标的高
const ICONTHIRDWIDTH = 25; // 三级图标的宽
const ICONTHIRDHEIGHT = 25; // 三级图标的高

const CIRCLEFIRSTWIDTH = 70; // 圆节点的宽
const CIRCLESECONDWIDTH = 60; // 圆节点的宽
const CIRCLETHIRDWIDTH = 50; // 圆节点的宽

const FONTOFFSET = 7;

// const COLORGREY = '#c0c0c0';
const COLORGREY = 'GREY';
// const COLORGREEN = '#3dce6d';
const COLORBLUE = '#4e81ec';
const COLORRED = '#d84242';
const COLORYELLOW = '#f59a23';
// const COLORPINK = '#f5c1cc';
// const COLORPULPER = '#bac2dd';
// const COLORINDIGO = '#4b749f';
// const COLOORANGE = '#fe6905';

// 圆动态背景 background-animate-circle
G6.registerNode(
  'background-animate-circle',
  {
    afterDraw(cfg, group) {
      // console.log('cfg => ', cfg);
      let r = cfg.size / 2;
      if (isNaN(r)) {
        r = cfg.size[0] / 2;
      }
      const iconZIndex = -1;
      const textZIndex = -1;
      const { width, height, img } = cfg.iconData;
      const isChipUnit = cfg.id.split('-')[0] === 'chipunit';
      const upY = 8; // 向上提升的数值
      const x = 0 - width / 2;
      const imageY = 0 - height / 2 - (isChipUnit && upY);

      // 画对应的图标
      group.addShape('image', {
        zIndex: iconZIndex,
        attrs: {
          x,
          y: imageY,
          width,
          height,
          img
        },
        name: 'icon-image-shape',
        draggable: false
      });

      // 如果是 采集器单元 设置对应的状态数据
      if (isChipUnit) {
        const {
          num
          // , unit
        } = cfg.textData;
        const downY = 15; // 向下的数值
        const textY = 0 + downY;
        const textX = 0;
        // let text = num + unit;
        // if (text.length > 6) {
        //   text = text.substr(0, 6) + '…';
        // }
        // 画对应的文字
        group.addShape('text', {
          zIndex: textZIndex,
          attrs: {
            text: num || '暂无',
            x: textX,
            y: textY,
            fontSize: 10,
            textAlign: 'center',
            textBaseline: 'middle',
            fill: '#000000'
          },
          // 在 G6 3.3 及之后的版本中，必须指定 name，可以是任意字符串，但需要在同一个自定义元素类型中保持唯一性
          name: 'description-text-shape',
          draggable: false
        });
      }

      // 来一个白色背景圆
      group.addShape('circle', {
        zIndex: -10,
        attrs: {
          x: 0,
          y: 0,
          r,
          fill: '#ffffff',
          opacity: 1
        },
        // must be assigned in F6 3.3 and later versions. it can be any value you want
        name: 'circle-shape0'
      });

      // 第一个背景圆
      const back1 = group.addShape('circle', {
        zIndex: -13,
        attrs: {
          x: 0,
          y: 0,
          r,
          fill: cfg.style.stroke,
          opacity: 1
        },
        // must be assigned in F6 3.3 and later versions. it can be any value you want
        name: 'circle-shape1'
      });
      // 第二个背景圆
      const back2 = group.addShape('circle', {
        zIndex: -12,
        attrs: {
          x: 0,
          y: 0,
          r,
          fill: cfg.style.stroke, // 为了显示清晰，随意设置了颜色
          opacity: 1
        },
        // must be assigned in F6 3.3 and later versions. it can be any value you want
        name: 'circle-shape2'
      });
      // 第三个背景圆
      const back3 = group.addShape('circle', {
        zIndex: -11,
        attrs: {
          x: 0,
          y: 0,
          r,
          fill: cfg.style.stroke,
          opacity: 1
        },
        // must be assigned in F6 3.3 and later versions. it can be any value you want
        name: 'circle-shape3'
      });
      group.sort(); // 排序，根据 zIndex 排序

      const magnify = 5;

      // 第一个背景圆逐渐放大，并消失
      back1.animate(
        {
          r: r + magnify,
          opacity: 0.1
        },
        {
          repeat: true, // 循环
          duration: 3000,
          easing: 'easeCubic',
          delay: 0 // 无延迟
        }
      );

      // 第二个背景圆逐渐放大，并消失
      back2.animate(
        {
          r: r + magnify,
          opacity: 0.1
        },
        {
          repeat: true, // 循环
          duration: 3000,
          easing: 'easeCubic',
          delay: 1000 // 1 秒延迟
        }
      ); // 1 秒延迟

      // 第三个背景圆逐渐放大，并消失
      back3.animate(
        {
          r: r + magnify,
          opacity: 0.1
        },
        {
          repeat: true, // 循环
          duration: 3000,
          easing: 'easeCubic',
          delay: 2000 // 2 秒延迟
        }
      );
    }
  },
  'circle'
);

// 设置动态线 line-running
G6.registerEdge(
  'line-running',
  {
    afterDraw(cfg, group) {
      // 获得当前边的第一个图形，这里是边本身的 path
      const shape = group.get('children')[0];
      // 边 path 的起点位置
      const startPoint = shape.getPoint(0);

      // 添加红色 circle 图形
      const circle = group.addShape('circle', {
        attrs: {
          x: startPoint.x,
          y: startPoint.y,
          fill: cfg.customData.stroke || 'grey',
          r: 3
        },
        // must be assigned in F6 3.3 and later versions. it can be any value you want
        name: 'circle-shape'
      });

      // 对红色圆点添加动画
      circle.animate(
        (ratio) => {
          // 每一帧的操作，入参 ratio：这一帧的比例值（Number）。返回值：这一帧需要变化的参数集（Object）。
          // 根据比例值，获得在边 path 上对应比例的位置。
          const tmpPoint = shape.getPoint(ratio);
          // 返回需要变化的参数集，这里返回了位置 x 和 y
          return {
            x: tmpPoint.x,
            y: tmpPoint.y
          };
        },
        {
          repeat: true, // 动画重复
          duration: 3000
        }
      ); // 一次动画的时间长度
    }
  },
  'line'
); // 该自定义边继承 line

// eslint-disable-next-line vue/no-setup-props-destructure
const { equipmentName, equipmentId } = defineProps({
  equipmentName: {
    type: String,
    required: true
  },
  equipmentId: {
    type: String,
    required: true
  }
});

const emit = defineEmits([
  'goCollectorDetailPage',
  'goCollectorUnitDetailPage'
]);

console.log('equipmentName', equipmentName);
console.log('equipmentId', equipmentId);

const container = ref(null);
let graph;

// 下面是 设备结构图所用到的参数
let equipmentDetail = reactive({});
const userInfo = JSON.parse(localStorage.getItem('userInfo'));
console.log('userInfo =>', userInfo);

const collectorNameArr = []; // 采集器名称数组
const chipUnitNamesArr = []; // 采集器单元名称数组

let collectorDataArr = []; // 采集器列表数据

onMounted(async () => {
  // 获取要展示的数据信息
  await getEquipmentDetail(equipmentId).then((res) => {
    const { code, data, msg } = res;
    if (code === 200) {
      equipmentDetail = data;
    } else {
      ElMessage.error(msg);
    }
  });

  await getCollectorList({ equipmentId }).then((res) => {
    const { code, data, msg } = res;
    if (code === 200) {
      collectorDataArr = data.list;
      collectorDataArr &&
        collectorDataArr.forEach((item) => {
          collectorNameArr.push(item.chipName);
          const currentChipUnitNameArr = [];
          item.metrics &&
            item.metrics.forEach((chipUnit) => {
              currentChipUnitNameArr.push(chipUnit.name);
            });
          chipUnitNamesArr.push(currentChipUnitNameArr);
        });

      console.log('collectorDataArr=>', collectorDataArr);
      console.log('collectorNameArr=>', collectorNameArr);
      console.log('chipUnitNamesArr=>', chipUnitNamesArr);
    } else {
      ElMessage.error(msg);
    }
  });

  // 获取el-dialog实例
  const width = 1400;
  const height = 800;
  // 数据
  const data = initG6Data();

  // 悬浮tips
  const tooltip = new G6.Tooltip({
    offsetX: 10,
    offsetY: 20,
    // className: 'tooltip',
    getContent(e) {
      const outDiv = document.createElement('div');
      // outDiv.style.width = '180px';
      const { textData, label, isLeaf } = e.item.getModel();
      if (!isLeaf) {
        outDiv.innerHTML = `
          <h4 class="tooltip-title">${label}</h4>
        `;
        return outDiv;
      }
      const { num, unit, status } = textData;
      let statusClass = 'color-blue';
      switch (status) {
        case '报警':
          statusClass = 'color-red';
          break;
        case '预警':
          statusClass = 'color-yellow';
          break;
        case '离线':
          statusClass = 'color-grey';
          break;
        default:
          break;
      }

      const str = num + unit;
      outDiv.innerHTML = `
      <h4 class="tooltip-title">${label}</h4>
      <ul class="tooltip-ul">
        <li class="tooltip-li">状态: <span class="${statusClass}">${status}</span></li>
        <li class="tooltip-li">数据: ${str || '暂无'}</li>
      </ul>`;
      return outDiv;
    },
    itemTypes: ['node']
  });

  // 创建G6图形
  graph = new G6.Graph({
    container: container.value,
    width,
    height,
    layout: {
      type: 'force',
      preventOverlap: true,
      linkDistance: (d) => {
        // console.log('d => ', d);
        // 第一层与第二层连接的长度为 100
        if (d.target.id === 'equipment') {
          return 250;
        }
        return 150;
      },
      nodeStrength: (d) => {
        // console.log('d => ', d);
        // console.log('d.isLeaf => ', d.isLeaf);
        if (d.isLeaf) {
          return -250;
        }
        return -100;
      },
      edgeStrength: (d) => {
        if (
          d.target.id === 'equipment' ||
          d.target.id.split('-')[0] === 'collector'
        ) {
          // console.log('不是leaf');
          return 0.7;
        }
        return 0.1;
      },
      nodeSpacing: (d) => {
        // console.log(d);
        if (d.id.split('-')[0] === 'collector') {
          return 80;
        }
        return 15;
      }
    },
    // defaultNode: {
    //   type: 'background-animate-circle',
    //   size: 100,
    //   color: '#5B8FF9',
    //   style: {
    //     fill: '#9EC9FF',
    //     lineWidth: 3
    //   },
    //   labelCfg: {
    //     style: {
    //       fill: '#fff',
    //       fontSize: 20
    //     }
    //   }
    // },
    defaultEdge: {
      // 边默认的属性，包括边的一般属性和样式属性（style）。
      // type: 'polyline',
      // color: '#000',
      style: {
        opacity: 0.6, // 边透明度
        stroke: 'grey' // 边描边颜色
        // radius: 10
      }
    },
    modes: {
      default: ['drag-canvas', 'zoom-canvas']
    },
    // eslint-disable-next-line new-cap
    plugins: [tooltip], // 配置 Tooltip 插件
    minZoom: 0.5, // Set the initial minimum zoom level
    maxZoom: 4 // Set the initial maximum zoom level
  });

  graph.data(data);
  graph.render();

  graph.on('afterlayout', () => {
    // 这里做一个判断
    // 如果采集器 大于 2 个 并且 采集器单元不为空的情况下, 自动缩放居中
    //  否则 只居中但是不缩放
    // 计算 chipUnitNamesArr 中不为空的数组个数
    if (collectorNameArr && chipUnitNamesArr) {
      let chipUnitNamesLength = 0;
      chipUnitNamesArr.forEach((chipUnitNameArr) => {
        chipUnitNameArr.length && chipUnitNamesLength++;
      });
      if (collectorNameArr.length > 2 && chipUnitNamesLength > 2) {
        // 使用 fitView 方法自动缩放并显示所有节点
        graph.fitView([20, 20, 20, 20], {}, true);
      } else {
        graph.fitCenter(true);
      }
    } else {
      graph.fitCenter(true);
    }
    setTimeout(() => {
      // 这里居中之后 限制画布最小缩放数,  -0.1是为了调整节点后有个安全距离
      graph.cfg.minZoom = graph.getZoom() - 0.1; // Set the new minimum zoom level
    }, 500);

    // 取消监听 afterlayout 事件，以确保只在初始加载时居中一次
    graph.off('afterlayout');
  });

  // 执行布局计算
  graph.layout();

  graph.on('node:click', (ev) => {
    const nodeItem = ev.item; // 获取被点击的节点元素对象
    console.log('打印 item ', nodeItem);
    // 跳转到对应的页面去
    // 分解一下
    const nodeId = nodeItem._cfg.id;
    const nodeIdArr = nodeId.split('-');
    const nodeType = nodeIdArr[0];
    const nodeTypeIndex = nodeIdArr[1] - 1;

    if (nodeType == 'collector') {
      const { member, chipId } = equipmentDetail.chipInfoList[nodeTypeIndex];
      if (!member) {
        if (equipmentDetail.userId != userInfo.id) {
          // ElMessage.warning(
          //   '尊敬的用户您好，当前设备的套餐服务已过期或未购买，请及时通知设备拥有者订购服务，以保障设备服务正常运行！'
          // );
          ElMessageBox.confirm(
            '尊敬的用户您好，当前设备的套餐服务已过期或未购买，请及时通知设备拥有者订购服务，以保障设备服务正常运行！',
            '温馨提示',
            {
              cancelButtonText: '我知道了',
              showConfirmButton: false,
              type: 'warning'
            }
          );
        }
        return;
      }
      ElMessageBox.confirm(
        `是否要跳转到${collectorNameArr[nodeTypeIndex]}的详情页面?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      )
        .then(() => {
          emit('goCollectorDetailPage', chipId);
          console.log('要调转到 采集器详情' + chipId);
        })
        .catch(() => {
          console.log('取消了!');
        });
    } else if (nodeType == 'chipunit') {
      const nodeTypeSecondIndex = nodeIdArr[2] - 1;
      console.log('nodeTypeIndex =>', nodeTypeIndex);
      console.log('nodeTypeSecondIndex =>', nodeTypeSecondIndex);
      // const equipmentAndChipName = chipData.equipmentAndChipName;
      // const id = chipData.metrics[nodeTypeSecondIndex].id;
      const { member, chipId, metrics } = collectorDataArr[nodeTypeIndex];
      if (!member) {
        if (equipmentDetail.userId != userInfo.id) {
          // ElMessage.warning(
          //   '尊敬的用户您好，当前设备的套餐服务已过期或未购买，请及时通知设备拥有者订购服务，以保障设备服务正常运行！'
          // );
          ElMessageBox.confirm(
            '尊敬的用户您好，当前设备的套餐服务已过期或未购买，请及时通知设备拥有者订购服务，以保障设备服务正常运行！',
            '温馨提示',
            {
              cancelButtonText: '我知道了',
              showConfirmButton: false,
              type: 'warning'
            }
          );
        }
        return;
      }
      const { id: UnitId } = metrics[nodeTypeSecondIndex];
      console.log('chipId=>', chipId);
      console.log('UnitId=>', UnitId);
      ElMessageBox.confirm(
        `是否要跳转到${chipUnitNamesArr[nodeTypeIndex][nodeTypeSecondIndex]}的详情页面?`,
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(() => {
        emit('goCollectorUnitDetailPage', chipId, UnitId);
        console.log('要调转到 采集单元详情!!!');
      });
    }
  });
  graph.on('node:dragstart', function (e) {
    console.log('dragstart=>', e);
    graph.layout();
    refreshDragedNodePosition(e);
  });
  graph.on('node:drag', function (e) {
    // console.log('drag=>', e);
    refreshDragedNodePosition(e);
  });
  graph.on('node:dragend', function (e) {
    console.log('dragend=>', e);
    e.item.get('model').fx = null;
    e.item.get('model').fy = null;
  });

  if (typeof window !== 'undefined') {
    window.onresize = () => {
      if (!graph || graph.get('destroyed')) return;
      if (
        !container.value ||
        !container.value.scrollWidth ||
        !container.value.scrollHeight
      ) {
        return;
      }
      graph.changeSize(
        container.value.scrollWidth,
        container.value.scrollHeight
      );
    };
  }

  function refreshDragedNodePosition(e) {
    const model = e.item.get('model');
    model.fx = e.x;
    model.fy = e.y;
  }
});

// 初始化 数据
function initG6Data() {
  // #region  第一层 设备
  const data = {
    nodes: [
      {
        id: 'equipment',
        type: 'background-animate-circle',
        size: CIRCLEFIRSTWIDTH,
        // x: 200,
        // y: 200,
        style: {
          fill: 'transparent', // 节点填充色
          stroke: COLORBLUE, // 节点的描边颜色
          // stroke: '#f4be43', // 节点的描边颜色
          lineWidth: 2 // 描边宽度
          // lineDash: [10, 5], // 描边虚线，数组代表实、虚长度 Number[]
          // shadowColor: "#f4be43", // 阴影颜色
          // shadowBlur: 10, // 阴影范围
          // shadowOffsetX: 0, // 阴影 x 方向偏移量
          // shadowOffsetY: 0, // 阴影 x 方向偏移量
          // opacity: 1, // 设置绘图的当前 alpha 或透明值
          // fillOpacity: 1, // 设置填充的 alpha 或透明值
        },
        label: equipmentName,
        labelCfg: {
          position: 'bottom',
          offset: FONTOFFSET,
          style: {
            fill: '#1b1b1b', // 文本颜色
            stroke: '#fff', // 文本描边颜色
            lineWidth: 2, // 文本描边粗细
            // opacity: 1, // 文本透明度
            // fontFamily: '微软雅黑 宋体, sans-serif', // 文本字体族
            fontSize: FONTSIZE
          }
        },
        iconData: {
          show: true,
          img: '/assets/topology/equipment.png',
          width: ICONFIRSTWIDTH,
          height: ICONFIRSTHEIGHT
        }
      }
    ],
    edges: []
  };
  // #endregion 第一层 设备

  if (collectorNameArr && collectorNameArr.length) {
    // #region 第二层 采集器
    for (let i = 0; i < collectorDataArr.length; i++) {
      const { statusStr } = collectorDataArr[i];
      console.log('statusStr => ', statusStr);
      let stroke = COLORBLUE;
      let type = 'line-running';
      let img = '/assets/topology/collector-blue.png';
      switch (statusStr) {
        case '正常': // 正常
          stroke = COLORBLUE;
          break;
        case '异常': // 异常
          stroke = COLORRED;
          img = '/assets/topology/collector-red.png';
          break;
        case '离线': // 离线
          stroke = COLORGREY;
          type = 'line';
          img = '/assets/topology/collector-grey.png';
          break;
        default:
          break;
      }
      console.log('stroke=>', stroke);
      data.nodes.push({
        id: 'collector-' + (i + 1),
        type: 'background-animate-circle',
        label: collectorNameArr[i],
        size: CIRCLESECONDWIDTH,
        style: {
          fill: 'transparent', // 节点填充色
          stroke, // 节点的描边颜色
          // stroke: '#55a66d', // 节点的描边颜色
          lineWidth: 2 // 描边宽度
          // lineDash: [10, 5], // 描边虚线，数组代表实、虚长度 Number[]
          // shadowColor: "#f4be43", // 阴影颜色
          // shadowBlur: 10, // 阴影范围
          // shadowOffsetX: 0, // 阴影 x 方向偏移量
          // shadowOffsetY: 0, // 阴影 x 方向偏移量
          // opacity: 1, // 设置绘图的当前 alpha 或透明值
          // fillOpacity: 1, // 设置填充的 alpha 或透明值
        },
        labelCfg: {
          position: 'bottom',
          offset: FONTOFFSET,
          style: {
            fill: '#1b1b1b', // 文本颜色
            stroke: '#fff', // 文本描边颜色
            lineWidth: 2, // 文本描边粗细
            // opacity: 1, // 文本透明度
            // fontFamily: '微软雅黑 宋体, sans-serif', // 文本字体族
            fontSize: FONTSIZE
          }
        },
        iconData: {
          show: true,
          width: ICONSECONDWIDTH,
          height: ICONSECONDHEIGHT,
          img
        }
      });
      data.edges.push({
        source: 'collector-' + (i + 1),
        target: 'equipment',
        id: 'edge' + i,
        type,
        customData: {
          stroke
        }
      });
    }
    // #endregion 第二层 采集器

    // #region 第三层 采集单元
    if (chipUnitNamesArr && chipUnitNamesArr.length) {
      for (let i = 0; i < collectorDataArr.length; i++) {
        const { metrics } = collectorDataArr[i];
        // 采集器单元
        const tempChipNames = chipUnitNamesArr[i];
        for (let j = 0; j < tempChipNames.length; j++) {
          const { status, metricValue, metricValueUnit } = metrics[j];
          let stroke = COLORBLUE;
          let type = 'line-running';
          let img = '/assets/topology/collectorUnit.png';
          switch (status) {
            case '正常': // 正常
              stroke = COLORBLUE;
              break;
            case '报警': // 报警
              stroke = COLORRED;
              img = '/assets/topology/collectorUnit-red.png';
              break;
            case '预警': // 预警
              stroke = COLORYELLOW;
              img = '/assets/topology/collectorUnit-yellow.png';
              break;
            case '离线': // 离线
              stroke = COLORGREY;
              img = '/assets/topology/collectorUnit-grey.png';
              type = 'line';
              break;
            default:
              break;
          }
          const chipName = tempChipNames[j];
          const chipId = `chipunit-${i + 1}-${j + 1}`;

          // 插入 node
          data.nodes.push({
            id: chipId,
            type: 'background-animate-circle',
            size: CIRCLETHIRDWIDTH,
            label: chipName,
            style: {
              fill: 'transparent', // 节点填充色
              stroke, // 节点的描边颜色
              // stroke: '#5d8cfb', // 节点的描边颜色
              lineWidth: 2 // 描边宽度
              // lineDash: [10, 5], // 描边虚线，数组代表实、虚长度 Number[]
              // shadowColor: "#f4be43", // 阴影颜色
              // shadowBlur: 10, // 阴影范围
              // shadowOffsetX: 0, // 阴影 x 方向偏移量
              // shadowOffsetY: 0, // 阴影 x 方向偏移量
              // opacity: 1, // 设置绘图的当前 alpha 或透明值
              // fillOpacity: 1, // 设置填充的 alpha 或透明值
            },
            labelCfg: {
              position: 'bottom',
              offset: FONTOFFSET,
              style: {
                // fill: '#1b1b1b', // 文本颜色
                stroke: '#fff', // 文本描边颜色
                lineWidth: 2, // 文本描边粗细
                // opacity: 1, // 文本透明度
                // fontFamily: '微软雅黑 宋体, sans-serif', // 文本字体族
                fontSize: FONTSIZE
              }
            },
            iconData: {
              show: true,
              img,
              width: ICONTHIRDWIDTH,
              height: ICONTHIRDHEIGHT
            },
            textData: {
              num: metricValue,
              unit: metricValueUnit,
              status,
              isLeaf: true
            },
            isLeaf: true
          });
          // 插入 edge
          const edgeId = `edge-${i + 1}-${j + 1}`;

          data.edges.push({
            source: chipId,
            target: `collector-${i + 1}`,
            id: edgeId,
            type,
            customData: {
              stroke
            }
          });
        }
      }
    }
    // #endregion 第三层 采集单元
  }
  return data;
}

onBeforeUnmount(() => {
  console.log('onBeforeUnmount');
  graph && graph.destroy();
  graph = null;
});
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
}
</style>

<style lang="scss">
// .tooltip {
//   width: 180px;
//   padding: 20px;
//   text-align: center;
//   border: 1px solid #707070;
//   background-color: rgba($color: #707070, $alpha: 0.3);

//   .tooltip-ul {
//     list-style: none;
//   }
// }
.tooltip-ul {
  list-style: none;
  .color-blue {
    color: #4e81ec;
  }
  .color-grey {
    color: #707070;
  }
  .color-red {
    color: #d84242;
  }
  .color-yellow {
    color: #f59a23;
  }
}
</style>

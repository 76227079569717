<template>
  <div class="weather_box_wrap">
    <div class="weather_left_box">
      <div class="weather_left_title_wrap">
        <div class="box_card_title_item">{{ weatherData.city }}</div>
        <div class="box_card_title_item">
          {{ weatherData.date + ' ' + weatherData.week }}
        </div>
      </div>
      <div class="weather_left_body_wrap">
        <div class="current_weater_row">
          <div class="current_weather_row_item weather_tem_row_item">
            <div class="weather_tem_item">
              {{ weatherData.tem + '°C' }}
            </div>
            <div class="weather_win_item">
              <div class="weather_win">
                {{ weatherData.win }}
              </div>
              <div class="weather_winSpeed">
                {{ weatherData.winSpeed }}
              </div>
            </div>
          </div>
          <div class="current_weather_row_item">
            <div class="weather_img_item">
              <img :src="`${baseURL}${weatherData.weaImg}`" alt="天气图片" />
            </div>
            <div class="weather_wea_item">
              <div class="weather_wea">
                {{ weatherData.wea }}
              </div>
              <div class="weather_minMaxWea">
                {{ weatherData.tem2 }}°C ~ {{ weatherData.tem1 }}°C
              </div>
            </div>
          </div>
        </div>
        <div class="current_weater_row">
          <div class="current_weather_row_item weather_aqi_rowitem">
            <div>
              <div>空气质量： {{ weatherData.aqi?.airLevel }}</div>
              <div>
                PM2.5： {{ weatherData.aqi?.pm25 }}
                {{ weatherData.aqi?.pm25Desc }}
              </div>
            </div>
            <div>
              <div>{{ weatherData.aqi?.kaichuang }}</div>
              <div>
                {{ weatherData.aqi?.waichu }}
              </div>
            </div>
          </div>
          <div class="current_weather_row_item weather_sun_row_item">
            <div>
              <div class="weather_sunrise">
                日出： {{ weatherData.sunrise }}
              </div>
              <div class="weather_sunrise">日落： {{ weatherData.sunset }}</div>
            </div>
            <div>
              <div>湿度： {{ weatherData.humidity }}</div>
              <div>气压： {{ weatherData.pressure }}Pa</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="weather_right_box">
      <div class="weather_right_box_title">24小时天气预报</div>
      <div class="weather_right_body_wrap">
        <el-scrollbar always>
          <div class="weather_scrollbar_box">
            <div
              class="weather_scrollbar_box_item"
              v-for="hoursItem in weatherData.hours"
              :key="hoursItem.hours"
            >
              <div class="box_item_row">{{ hoursItem.hours }}</div>
              <div class="box_item_img">
                <img :src="`${baseURL}${hoursItem.weaImg}`" alt="天气图片" />
              </div>
              <div class="box_item_row">{{ hoursItem.wea }}</div>
              <div class="box_item_row">{{ hoursItem.tem }}°C</div>
              <div class="box_item_row">{{ hoursItem.win }}</div>
              <div class="box_item_row">{{ hoursItem.winSpeed }}</div>
              <div class="box_item_row">{{ hoursItem.aqi }}</div>
              <div class="box_item_row">{{ hoursItem.aqiNum }}</div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, defineProps, watchEffect } from 'vue';
import { ElMessage } from 'element-plus';
import { getAreaWeather } from '@/api/equipment';
const baseURL = process.env.VUE_APP_BASE_API;

// eslint-disable-next-line vue/no-setup-props-destructure
const props = defineProps({
  cityCode: {
    type: String,
    require: true
  }
});

// 天气数据
const weatherData = reactive({
  aqi: {},
  // {
  //   airLevel: '', // 空气质量
  //   kaichuang: '', // 适宜开窗
  //   pm25: '', // PM2.5
  //   pm25Desc: '', // PM2.5描述
  //   waichu: '' // 适宜外出
  // },
  city: '', // 城市中文名称
  date: '', // 日期 当前时间
  hours: [
    // 24小时天气预报
    // {
    //   aqi: '',
    //   aqiNum: '',
    //   hours: '',
    //   tem: '',
    //   wea: '',
    //   weaImg: '',
    //   win: '',
    //   winSpeed: ''
    // }
  ],
  week: '', // 周几 当前星期
  tem: '', // 实时温度 例子: 30
  win: '', // 风向 例子: 西南风
  winSpeed: '', // 风力等级 例子: 1级
  weaImg: '', // 天气情况图标 例子: '/profile/logo/cucumber/yun.png'
  wea: '', // 天气 例子: '多云'
  tem2: '', // 最低温度 例子: '28'
  tem1: '', // 最高温度 例子: '36'
  sunrise: '', // 日出时间 例子: '03:50'
  sunset: '', // 日落时间 例子: '19:23'
  humidity: '', // 湿度 例子: '75%'
  pressure: '' // 气压 例子: '994'
});

// 获取天气数据
const getWeather = (cityCode) => {
  if (cityCode) {
    const areaCode = Number(
      Array.from(cityCode + '')
        .splice(0, 4)
        .join('') + '00'
    );
    const data = {
      areaCode,
      cityCode
    };
    getAreaWeather(data).then((res) => {
      const { data, code, msg } = res;
      if (code === 200) {
        console.log(data);
        for (const key of Object.keys(weatherData)) {
          weatherData[key] = data[key];
        }
      } else {
        console.log('getWeather 报错了!');
        ElMessage.error(msg);
      }
    });
  }
};

watchEffect(() => {
  getWeather(props.cityCode);
});
</script>

<style lang="scss" scoped>
.weather_box_wrap {
  width: 100%;
  display: flex;
  .weather_left_box {
    width: 33%;
    padding-right: 15px;
    border-right: 1px solid #e8e8e8;
    .weather_left_title_wrap {
      display: flex;
      justify-content: space-between;
      font-size: 18px;
    }

    .weather_left_body_wrap {
      width: 100%;
      .current_weater_row {
        display: flex;
        justify-content: space-around;
        align-items: center;

        .current_weather_row_item {
          width: 50%;
          height: 120px;
          // background-color: #1890ff;
          // border-radius: 10px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-around;
          font-size: 14px;

          &.weather_tem_row_item {
            justify-content: flex-start;
            .weather_tem_item {
              font-weight: 500;
              font-size: 27px;
              margin-top: 19px;
            }
            .weather_win_item {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 21px;
              .weather_win {
                height: 23px;
                line-height: 23px;
              }
              .weather_winSpeed {
                height: 23px;
                line-height: 23px;
              }
            }
          }

          .weather_wea_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            .weather_wea {
              height: 23px;
              line-height: 23px;
            }
            .weather_minMaxWea {
              height: 23px;
              line-height: 23px;
            }
          }

          &.weather_aqi_rowitem {
            justify-content: space-evenly;
          }

          &.weather_sun_row_item {
            justify-content: space-evenly;
          }

          .weather_img_item {
            text-align: center;
            img {
              width: 50px;
            }
          }
        }
      }
    }
  }
  .weather_right_box {
    width: 67%;
    // background-color: greenyellow;
    padding-left: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .weather_right_box_title {
      display: flex;
      justify-content: center;
      font-size: 18px;
      font-weight: 600;
    }
    .weather_right_body_wrap {
      .weather_scrollbar_box {
        display: flex;
        .weather_scrollbar_box_item {
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          align-items: center;
          width: 85px;

          .box_item_img {
            img {
              width: 50px;
            }
          }
          .box_item_row {
            height: 23px;
            line-height: 23px;
            font-size: 14px;
          }
        }
        padding: 15px;
        padding-left: 0;
      }
    }
  }
}
</style>

<template>
  <div class="cropper-content">
    <div class="cropper-box">
      <div class="cropper">
        <VueCropper
          ref="cropper"
          :img="option.img"
          :outputSize="option.outputSize"
          :outputType="option.outputType"
          :info="option.info"
          :canScale="option.canScale"
          :autoCrop="option.autoCrop"
          :autoCropWidth="option.autoCropWidth"
          :autoCropHeight="option.autoCropHeight"
          :fixed="option.fixed"
          :fixedNumber="option.fixedNumber"
          :full="option.full"
          :fixedBox="option.fixedBox"
          :canMove="option.canMove"
          :canMoveBox="option.canMoveBox"
          :original="option.original"
          :centerBox="option.centerBox"
          :height="option.height"
          :infoTrue="option.infoTrue"
          :maxImgSize="option.maxImgSize"
          :enlarge="option.enlarge"
          :mode="option.mode"
          @realTime="realTime"
          @imgLoad="imgLoad"
        >
        </VueCropper>
      </div>
      <!--底部操作工具按钮-->
      <div class="footer-btn">
        <div class="scope-btn">
          <input
            type="file"
            id="uploads"
            style="position: absolute; clip: rect(0 0 0 0)"
            accept="image/png, image/jpeg, image/gif, image/jpg"
            @change="selectImg($event)"
          />
          <el-button
            size="small"
            type="danger"
            plain
            :icon="Plus"
            @click="changeScale(1)"
            >放大</el-button
          >
          <el-button
            size="small"
            type="danger"
            plain
            :icon="Minus"
            @click="changeScale(-1)"
            >缩小</el-button
          >
          <el-button
            size="small"
            type="danger"
            plain
            @click="rotateLeft"
            :icon="RefreshLeft"
            >左旋转</el-button
          >
          <el-button
            size="small"
            type="danger"
            plain
            @click="rotateRight"
            :icon="RefreshRight"
            >右旋转</el-button
          >
        </div>
      </div>
      <div class="upload-btn">
        <label class="select_btn" for="uploads"
          ><el-icon :size="14" class="select_icon"><PictureFilled /></el-icon
          >选择图片</label
        >
        <el-button type="success" @click="uploadImg" :icon="Upload"
          >确认上传</el-button
        >
      </div>
    </div>
    <!--预览效果图-->
    <!-- <div class="show-preview">
      <div class="preview">
        <img :src="previewImg" class="preview_img" />
      </div>
      <div class="upload-btn">
        <label class="select_btn" for="uploads"
          ><el-icon :size="14" class="select_icon"><PictureFilled /></el-icon
          >选择图片</label
        >
        <el-button type="success" @click="uploadImg" :icon="Upload"
          >确认上传</el-button
        >
      </div>
    </div> -->
  </div>
</template>

<script setup>
import 'vue-cropper/dist/index.css';
import { VueCropper } from 'vue-cropper';
import { uploadFile } from '@/api/common'; // 这里为文件上传的接口换成自己的文件
import { defineProps, defineEmits, reactive, ref } from 'vue';
import { ElMessage } from 'element-plus';
import {
  Plus,
  Minus,
  RefreshRight,
  RefreshLeft,
  PictureFilled,
  Upload
} from '@element-plus/icons-vue';

const props = defineProps({
  Name: {
    type: String,
    require: true
  }
});
const emit = defineEmits(['uploadImgSuccess']);

// const name = ref(props.Name);
const resImg = ref('');
const previews = reactive({});
const previewImg = ref('');

// VueCropper组件
const cropper = ref(null);

const option = reactive({
  img: props.Name, // 裁剪图片的地址
  outputSize: 1, // 裁剪生成图片的质量(可选0.1 - 1)
  outputType: 'png', // 裁剪生成图片的格式（jpeg || png || webp）
  info: true, // 图片大小信息
  canScale: true, // 图片是否允许滚轮缩放
  autoCrop: true, // 是否默认生成截图框
  autoCropWidth: 300, // 默认生成截图框宽度
  autoCropHeight: 200, // 默认生成截图框高度
  fixed: true, // 是否开启截图框宽高固定比例
  fixedNumber: [3, 2], // 截图框的宽高比例
  full: false, // false按原比例裁切图片，不失真
  fixedBox: false, // 固定截图框大小，不允许改变
  canMove: true, // 上传图片是否可以移动
  canMoveBox: true, // 截图框能否拖动
  original: false, // 上传图片按照原始比例渲染
  centerBox: true, // 截图框是否被限制在图片里面
  height: false, // 是否按照设备的dpr 输出等比例图片
  infoTrue: false, // true为展示真实输出图片宽高，false展示看到的截图框宽高
  maxImgSize: 3000, // 限制图片最大宽度和高度
  enlarge: 1, // 图片根据截图框输出比例倍数
  // mode: '340px 300px' // 图片默认渲染方式
  mode: '420px 280px' // 图片默认渲染方式
});

// 初始化函数
function imgLoad(msg) {
  console.log('工具初始化函数=====' + msg);
}
// 图片缩放
function changeScale(num) {
  num = num || 1;
  cropper.value.changeScale(num);
}
// 向左旋转
function rotateLeft() {
  cropper.value.rotateLeft();
}
// 向右旋转
function rotateRight() {
  cropper.value.rotateRight();
}
// //实时预览函数
function realTime(data) {
  previews.value = data;
  cropper.value.getCropBlob((data) => {
    blobToDataURI(data, function (res) {
      // console.log(res); // 这里可以打印base64
      cropper.value.previewImg = res;
      previewImg.value = res;
    });
  });
}
function blobToDataURI(blob, callback) {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  reader.onload = function (e) {
    callback(e.target.result);
  };
}
// 选择图片
function selectImg(e) {
  const file = e.target.files[0];
  if (!/\.(jpg|jpeg|png|JPG|PNG)$/.test(e.target.value)) {
    ElMessage.error('图片类型要求：jpeg、jpg、png');
    return false;
  }
  // 转化为blob
  const reader = new FileReader();
  reader.onload = (e) => {
    let data;
    if (typeof e.target.result === 'object') {
      data = window.URL.createObjectURL(new Blob([e.target.result]));
    } else {
      data = e.target.result;
    }
    option.img = data;
  };
  // 转化为base64
  reader.readAsDataURL(file);
}

function base64ImgtoFile(dataurl, filename = 'file') {
  // 将base64格式分割：['data:image/png;base64','XXXX']
  const arr = dataurl.split(',');
  // .*？ 表示匹配任意字符到下一个符合条件的字符 刚好匹配到：
  // image/png
  const mime = arr[0].match(/:(.*?);/)[1]; // image/png
  // [image,png] 获取图片类型后缀
  const suffix = mime.split('/')[1]; // png
  const bstr = atob(arr[1]); // atob() 方法用于解码使用 base-64 编码的字符串
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], `${filename}.${suffix}`, {
    type: mime
  });
}

function uploadFileFunc(file) {
  const formData = new FormData();
  formData.append('file', file);
  uploadFile(formData).then((res) => {
    if (res.code == 200) {
      ElMessage.success('上传照片成功!');
      emit('uploadImgSuccess', res.data);
    } else {
      ElMessage.error({
        message: '上传失败',
        duration: 1000
      });
    }
  });
}
// 上传图片
function uploadImg() {
  cropper.value.getCropData((data) => {
    resImg.value = base64ImgtoFile(data);
    uploadFileFunc(resImg.value);
  });
}
</script>

<style scoped lang="scss">
.cropper-content {
  display: flex;
  display: -webkit-flex;
  justify-content: flex-end;
  .cropper-box {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .cropper {
      width: 600px;
      height: 400px;
    }
  }

  .show-preview {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    .preview {
      overflow: hidden;
      height: 200px;
      width: 300px;
      background: #cccccc;
      transform: scale(0.8);
    }
  }
}
.footer-btn {
  margin-top: 15px;
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  .scope-btn {
    display: flex;
    display: -webkit-flex;
    justify-content: space-between;
    padding-right: 10px;
  }
  .upload-btn {
    flex: 1;
    -webkit-flex: 1;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
  }
}
.upload-btn {
  display: flex;
  align-items: center;
  margin-top: 15px;

  .select_btn {
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    // -webkit-appearance: none;
    text-align: center;
    // -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: 0;
    // -webkit-transition: 0.1s;
    transition: 0.1s;
    font-weight: 500;
    padding: 8px 15px;
    font-size: 14px;
    border-radius: 3px;
    color: #fff;
    background-color: #409eff;
    border-color: #409eff;
    margin-right: 10px;
    height: 32px;
    width: 108px;
    display: flex;
    justify-content: center;
    align-items: center;

    .select_icon {
      margin-right: 8px;
    }
  }
}
</style>
